import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API_RESPONSE, USER_DATA, SELECTED_HOTEL_ID, SELECTED_HOTEL_NAME, } from '../constants';
import { actionResponseHandler, actionErrorHandler } from '../components/utils/responseHandler';
import { checkSessionStatus, clearSession } from '../redux/actions/session_actions'
import {
	isMobile
} from "react-device-detect"
import {
	Grid,
} from "semantic-ui-react";
import Lottie from "react-lottie-player";
import newExtranetImages from "../assets";
export default function (ComposedClass, isAuthRequired, adminRoute = null) {
	class AuthenticationCheck extends Component {

		state = {
			loading: true,
			userData: '',
			selectedHotelID: -1,
			selectedHotelName: ''
		}

		componentDidMount() {
			let response = null;
			let userData = JSON.parse(localStorage.getItem(USER_DATA));
			let token = userData ? userData.token : null;
			if (token && token.length > 20) {
				let apiData = {
					"login_user_id": userData.login_user_id,
					"login_user_name": userData.login_user_name,
					"login_user_type": userData.user_access_type
				}
				this.props.dispatch(checkSessionStatus(apiData))
					.then(() => {
						response = this.props.session;
						if (response.status === API_RESPONSE.SUCCESS) {
							// this.props.session.data["policy_type"]="NONE";
							let policy_type = this.props.session.data.policy_type;
							localStorage.setItem(USER_DATA, JSON.stringify(this.props.session.data));

							let userData = JSON.parse(localStorage.getItem(USER_DATA));

							let selectedHotel = this.props.session.data.hotels.find(
								hotel => hotel.hotel_id === response.selectedHotelID
							);

							// let agree_key=JSON.parse(localStorage.getItem("agree"));
							// localStorage.setItem(SELECTED_HOTEL_ID, userData.hotels[0].hotel_id);
							// localStorage.setItem(SELECTED_HOTEL_NAME, userData.hotels[0].hotel_name);
							let permissions = this.props.session.data.permissions
							// if (true){
							// 	this.props.history.push("/agreement");
							// }
							// if (policy_type == "DISPLAY" && this.props.match.path != "/policy-update" && this.props.match.path != "/policy") {
							// 	this.props.history.push("/policy-update");
							// }
							if ((this.props.match.path === 'hotel-finance/summary' || this.props.match.path === '/inventory/update-rooms-and-rates' || this.props.match.path === 'hotelfinance' || this.props.match.path === '/profile' || this.props.match.path === '/summary' || this.props.match.path === '/bookings' || this.props.match.path === '/new/policy' || this.props.match.path === '/hotelfinance' || this.props.match.path === '/new') && (this.props.session.data.hotels === null || this.props.session.data.hotels.length === 0)) {
								this.props.history.push("/new-user_m");
							}

							if (selectedHotel?.agreement_type === "DISPLAY" &&
								(this.props.match.path != "/agreement" ||
									this.props.match.path != "/agreement")
							) {

								if (!isMobile) {
									if (this.props.location.pathname == "/" || this.props.location.pathname.includes("/")) {
										this.props.history.push("/agreement")
									}
									else {
										this.props.history.push("/agreement")

									}
								}
								else {
									this.props.history.push("/agreement")
								}
							}
							if (selectedHotel?.agreement_type !== "DISPLAY" &&
								this.props.match.path != "/foreign-guest" &&
								this.props.match.path != "/foreign-guest" &&
								selectedHotel?.allow_foreign_guest === "DISPLAY") {
								if (this.props.match.path == "/" || this.props.match.path == "/") {
									this.props.history.push("/foreign-guest")

								}
								else {
									this.props.history.push("/foreign-guest")
								}

							}
							if (!isAuthRequired) {
								this.props.history.push("/");
							}
							if (this.props.match.path === '/rates' && this.props.session.data.user_rate_access === 'na') {
								this.props.history.push("/unauthorized");
							}
							if (this.props.match.path === '/hotelfinance' && permissions.uep_finance === '0') {
								this.props.history.push("/unauthorized");
							}
							if (this.props.match.path === '/profile' && permissions.uep_hotel_profile === '0') {
								this.props.history.push("/unauthorized");
							}
							if (this.props.match.path === '/inventory' && permissions.uep_user_rate_access === 'na') {
								this.props.history.push("/unauthorized");
							}
							if (this.props.match.path === '/bookings' && permissions.uep_view_bookings === '0') {
								this.props.history.push("/unauthorized");
							}
							if (this.props.match.path === '/amenities' && permissions.uep_amenities === '0') {
								this.props.history.push("/unauthorized");
							}
							if (this.props.match.path === '/user-access' && permissions.uep_add_manager === '0') {
								this.props.history.push("/unauthorized");
							}

							//NEW
							if (this.props.match.path === '/hotelfinance' && permissions.uep_finance === '0') {
								this.props.history.push("/unauthorized");
							}
							if ((this.props.match.path === '/hotelfinance' || this.props.match.path === '/profile' || this.props.match.path === '/amenities' || this.props.match.path === '/user-access' || this.props.match.path === '/new/manage-rooms' || this.props.match.path === '/bookings' || this.props.match.path === '/new/policy' || this.props.match.path === '/hotelfinance' || this.props.match.path === '/new') && (this.props.session.data.hotels == null || this.props.session.data.hotels.length == 0)) {
								this.props.history.push("/unauthorized");
							}
							if (this.props.match.path === '/profile' && permissions.uep_hotel_profile === '0') {
								this.props.history.push("/unauthorized");
							}
							if (this.props.match.path === '/hotel-property' && permissions.uep_hotel_profile === '0') {
								this.props.history.push("/unauthorized");
							}
							if (this.props.match.path === '/new/manage-rooms' && permissions.uep_user_rate_access === 'na') {
								this.props.history.push("/unauthorized");
							}
							if (this.props.match.path === '/bookings' && permissions.uep_view_bookings === '0') {
								this.props.history.push("/unauthorized");
							}
							if (this.props.match.path === '/amenities' && permissions.uep_amenities === '0') {
								this.props.history.push("/unauthorized");
							}
							if (this.props.match.path === '/user-access' && permissions.uep_add_manager === '0') {
								this.props.history.push("/unauthorized");
							}
							//

							if (this.props.match.url === '/internal/coupon' && permissions.uep_marketing === '0') {
								this.props.history.push("/unauthorized");
							}
							if (this.props.match.url === '/internal/admin-dashboard' && permissions.uep_admin_dashboard === '0') {
								this.props.history.push("/unauthorized");
							}
							if (this.props.match.url === '/internal/unlisted-hotels' && permissions.uep_hotel_listing === '0' && permissions.uep_closed_hotels === "0") {
								this.props.history.push("/unauthorized");
							}
							if (this.props.match.url === '/internal/finance' && permissions.uep_finance_inhouse === '0') {
								this.props.history.push("/unauthorized");
							}
							if (this.props.match.url === '/internal/hotel-balance-list' && permissions.uep_finance_inhouse === '0') {
								this.props.history.push("/unauthorized");
							}
							if (this.props.match.url === '/internal/activity-logs' && permissions.uep_finance_inhouse === '0') {
								this.props.history.push("/unauthorized");
							}
							if (this.props.match.url === '/internal/user-permissions' && permissions.uep_user_management === '0') {
								this.props.history.push("/unauthorized");
							}
							if (this.props.match.url === '/internal/s3' && permissions.uep_s3_bucket === '0') {
								this.props.history.push("/unauthorized");
							}

						}
						else {
							if (token && token.length > 20) {
								console.log(" token exists1");
								this.props.history.push({
									pathname: "/internal-server-error",
									state: "No Token Exists"
								})

							}
							else {
								console.log("no token exists");

								this.props.dispatch(clearSession());
								this.props.history.push("/session-timeout");
							}
						}
						this.setState({ loading: false });
					})
					.catch((error) => {
						console.log("catch error", error);
						this.setState({ loading: false })

						if (token && token.length > 20) {
							console.log(" token exists2");
							// this.props.history.push({
							// 	pathname: "/internal-server-error",
							// 	state: error
							// })
							console.log("__CatchBlockError__", error);

						}
						else {
							console.log("no token exists");

							this.props.dispatch(clearSession());
							this.props.history.push("/session-timeout");
						}
					})
			}
			else {
				this.setState({ loading: false })
				if (isAuthRequired) {
					this.props.history.push('login');
				} else {
				}
			}

		}


		render() {
			if (this.state.loading) {
				return (
					<div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>

						<Lottie
							loop={true}
							animationData={newExtranetImages.Loader}
							play
							style={{
								width: 50,
								height: 50,
							}}
						/>
						<p
							style={{
								cursor: "pointer",
								fontFamily: "Poppins",
								fontSize: "20px",
								fontStyle: "normal",
								fontWeight: "400",
								lineHeight: "30px",
								color: "#3E3E3E",
							}}
						>
							Loading...
						</p>

					</div>
				)
			}
			return (
				<div className={
					isMobile ?
						this.props.location.pathname === "/create-room-category" ||
							this.props.location.pathname.includes("/room-category") ||
							this.props.location.pathname.includes("/rate-plan")
							? null
							: this.props.location.pathname.includes("/holida-inventory") ? "holida_mobile_container"
								: "mobile_app_container" : "new_app_container"
				}
				>
					<ComposedClass {...this.props} userData={this.props.session} />
				</div>
			);
		}
	}

	const mapStateToProps = (state) => {
		return {
			session: state.session.response
		}
	}
	return connect(mapStateToProps)(AuthenticationCheck)
}


